.eroare_formular {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  padding-bottom: 0;
  background: none;
  p {
    font-family: "Montserrat-Medium";
    font-size: 15px;
    color: #ff3333;
    margin-bottom: 0;
    padding-left: 0;
    font-weight: 600;
  }
}
#text_formular_ok {
  font-family: "Trocchi";
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 40px;
}

.campuri {
  & > div:not(.disclaimer):not(.eroare_formular) {
    display: flex;
    flex-wrap: wrap;
  }
  .rezervare_persoane {
    .simple-dropdown-container {
      width: 10vw;
    }
  }
  .ora {
    .simple-dropdown-option-container {
      width: 100%;
    }
    .simple-dropdown-option-container {
      left: 12%;
    }
  }
  .confirm {
    p {
      margin: 0;
    }
  }

  .nume_client,
  .rezervare_persoane,
  .data,
  .confirm,
  .phone_and_mail,
  .message {
    &>p,
    //   &>label,
      &>input,
      &>div,
      &>span {
      margin-bottom: 1.33vw;
    }
    @media (max-width: 1024px) {
      &>p,
        //   &>label,
          &>input,
          &>div,
          &>span {
        margin-bottom: 20px;
      }
    }
    @media (max-width: 768px) {
      &>p,
        //   &>label,
          &>input,
          &>div,
          &>span {
        margin-bottom: 0;
      }
      p {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  .datepicker-reservation-container {
    input {
      margin-top: 1vw;
    }
    @media (max-width: 1024px) {
      input {
        margin-top: 13px;
      }
    }
    @media (max-width: 768px) {
      input {
        // margin-top: 13px;
      }
    }
  }
  .push-down-dropdown {
    .simple-dropdown-container {
      margin-top: 1vw;
    }
    @media (max-width: 1024px) {
      .simple-dropdown-container {
        margin-top: 13px;
      }
    }
    @media (max-width: 768px) {
      .simple-dropdown-container {
        // margin-top: 13px;
      }
    }
  }
}

#section4 {
  min-height: 100vh;
  position: relative;
  height: auto !important;
  #map_canvas {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.nume_client input {
  padding-left: 0.88vw;
}

//delivery form - START
.command_receipt_delivery_item_cont {
  &.command-reciept-input-with-dropdown {
    .command-receipt-form-line-left,
    .command-receipt-form-right {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .command-receipt-form-line-left {
      flex: 1;
    }
    .command-receipt-form-right {
      min-width: 150px;
      max-width: 50%;
      padding: 0 10px;
      margin-left: 20px;
      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
//delivery form - END

@media (max-width: 1024px) {
  .campuri {
    .ora {
    }
  }

  .datepicker_reservations_calendar {
    .react-datepicker__week,
    .react-datepicker__day-names,
    .react-datepicker__header {
      div {
        font-size: 16px !important;
      }
    }
  }

  .ui-state-default {
    font-size: 15px;
  }

  .button_vezi_meniu a {
    font-size: 17px;
    border-radius: 40px;
  }

  .campuri > div {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 25px;
  }
  .rezerv_check_square {
    height: 16px !important;
    width: 16px !important;
    padding-bottom: 0 !important;
  }
  .hours_selector_cm .select-selected {
    z-index: auto !important;
  }

  .data p {
    font-size: 15px;
    padding-right: 5px;
  }

  .confirm p {
    font-size: 15px;
  }

  .nume_client input {
    padding-left: 5px;
  }
  .footer_links a {
    font-size: 14px;
  }

  .campuri label {
    font-size: 15px;
    padding-right: 5px;
  }
  .content_formular input {
    font-size: 15px;
    line-height: 25px;
  }

  .select-items div,
  .select-selected {
    color: #ffffff;
    cursor: pointer;
    user-select: none;
    padding-left: 5px;
  }

  .data_here input {
    padding-left: 10px;
    line-height: 25px;
    width: 115px;
  }

  .footer_links {
    position: relative;
    height: 50px;
  }
  .program_text p {
    font-size: 20px;
    margin-top: 26px;
    margin-bottom: 50px;
  }
  .program_text {
    margin-top: 30px;
  }
  .program_text p span {
    font-size: 19px;
  }
}
@media (max-width: 768px) {
  #section4 {
    min-height: unset;
    position: relative;
    height: auto !important;
    .cont_s4 {
      height: auto !important;
      #map_canvas {
        position: initial !important;
      }
    }
  }
  .campuri {
    .nume_client,
    .rezervare_persoane,
    .data.rezervare_persoane,
    .confirm,
    .phone_and_mail,
    .message {
      & > input,
      & > div,
      & > p,
      & > span {
        width: 100%;
      }
      .simple-dropdown-container,
      .option-select-label-selected {
        width: 100% !important;
        line-height: 25px;
      }
      display: block !important;
    }
    .rezervare_persoane,
    .ora {
      display: block !important;
      .simple-dropdown-container,
      .simple-dropdown-option-container {
        width: 100%;
      }
      .simple-dropdown-option-container {
        left: 0;
      }
    }
    .data.rezervare_persoane {
      width: 100%;
      span {
        width: 100%;
        .react-datepicker-wrapper {
          width: 100%;
          .react-datepicker__input-container {
            width: 100%;
          }
        }
      }
    }
  }
  .check_agree {
    div {
      line-height: 20px;
    }
  }
  #section2 {
    .slider_section2 {
      .slick-slide {
        img {
          min-height: 100%;
        }
      }
    }
  }
  .ecran_formular_initial {
    height: auto;
    padding-bottom: 32px;
  }
  .ora-stay {
    p {
      width: 100%;
    }
  }
  .disclaimer {
    margin-top: 20px !important;
  }
  .eroare_formular {
    margin-bottom: 15px;
  }

  #text_formular_ok {
    font-weight: 400;
    font-size: 17px;
    padding: 20px 48px;
  }
  .generic_button_container {
    font-size: 15px;
  }
  .ecran_formular_initial {
    min-height: 45vh;
  }
  [last-item="true"] {
    margin-bottom: 25px !important;
  }
  [subset-item="true"] {
    margin-bottom: 10px !important;
  }
}
.use_reservation_stay_form {
  .simple-dropdown-container {
    width: 5vw !important;
  }
  .ora-stay {
    flex-wrap: wrap;
  }
}

.flex-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.padding-top-spacing {
  padding-top: 20px;
}
.error_text {
  font-family: "Trocchi";
  color: red;
}
