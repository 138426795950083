.bundle-assoc-item-sub-list {
  font-family: "Trocchi";
  font-size: 13px;
  width: 100%;
  text-align: left;
  padding-top: 7px;
}
@media only screen and (max-width: 1400px) {
  .bundle-assoc-item-sub-list {
    font-size: 11px;
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.emptySpace {
  height: 45px;
}

/** UTILS **/
.position-absolute {
  position: absolute;
}
.absolute-top {
  top: 0;
}
.absolute-right {
  right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}

.lang-container {
  display: flex;
  gap: 5px;
  margin-top: 28px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99999;
  width: 100%;

  .lang-select {
    font-size: 15px;
    font-family: "Trocchi";
    border: none;
    z-index: 9999;
    background-color: transparent;
    color: white;

    option {
      color: black; /* Black text for dropdown options */
    }
  }

  .lang-select-dark {
    color: black;
  }

  .lang-select:focus-visible {
    outline: none;
  }

  .lang-flag {
    height: 15px;
    width: auto !important;
    margin: 0 !important;
  }
}

@media (max-width: 768px) {
  .lang-container {
    margin-top: 0px;
    margin-bottom: 28px;
  }

  .lang-container-meniu {
    margin-bottom: 0px;
    margin-top: 28px;
  }
}
