body {
  margin: 0;
}
.jost {
  font-family: "Jost", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.no_decoration {
  text-decoration: none;
  color: black;
}

// @media screen and (max-width: 768px) {
/* Categories */
.content-section {
  width: 84%;
  margin: 0 auto;
}
.top-section {
  display: flex;
  justify-content: space-between;
  // padding: 22px 0 20px 0;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fdf0e8;

  padding: 0;
  justify-content: center;
  align-items: center;
  height: 75px;
}
.prev-arrow {
  font-size: 17px;
  padding-top: 7px;
  color: black;
}
.page-title {
  font-weight: 600;
  font-size: 22px;
  padding-left: 25px;
  padding-right: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-button-top {
  color: transparent;
  font-size: 17px;
  padding-top: 7px;
}
.search-form {
  box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.1);
}
.form.search-form input[type="text"] {
  width: 88%;
  -webkit-appearance: none;
  padding: 10px 0 10px 20px;
  font-size: 16px;
  float: left;
  background: white;
  color: black;
  border: 1px solid white;
  outline: none;
  font-weight: 600;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 47px;
  box-sizing: border-box;
}
.content-container-menu {
  z-index: 4;
  input::placeholder {
    color: black;
    font-weight: 600;
    font-size: 16;
  }
}

.form.search-form button {
  float: left;
  width: 12%;
  padding: 13.5px 20px 13px 0;
  background: white;
  color: black;
  font-size: 16px;
  border-left: none;
  cursor: pointer;
  font-weight: 600;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
.form.search-form::after {
  content: "";
  clear: both;
  display: table;
}
.categories {
  padding-top: 38px;
}
.categories-inner {
  display: flex;
  justify-content: space-between;
}
.categories-inner-notfirst {
  padding-top: 22px;
}
.category-image {
  height: 145px;
  width: 145px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.1);
}
.category-title {
  font-size: 18px;
  font-weight: 600;
  padding-top: 13px;
}
.category-number {
  font-size: 12px;
  font-weight: 600;
  color: #f4b58e;
}
/* #Categories */
/* Products */
.products {
  padding-top: 37px;
  position: relative;
}
.product-inner {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.1);
  margin-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  flex-wrap: wrap;
}

.product-inner-left {
  flex: 1;
  overflow: hidden;
  .product-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.product-price {
  width: 25%;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-add-btn {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  padding-top: 2px;
  border: 2px solid #eef0f1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus-btn {
  color: #ff7f00;
  font-size: 14px;
  padding: 7px 8px 8px 8.5px;
}
.check-btn {
  display: none !important;
  font-size: 14px;
  padding: 8px;
  background: #ff7f00;
  color: white;
  border-radius: 40px;
}
.product-name {
  font-size: 18px;
  font-weight: 600;
}
.product-weight {
  margin-top: 9px;
  align-items: flex-start;
  display: flex;
  > div {
    width: auto;
    font-size: 10px;
    font-weight: 600;
    color: #989ea3;
    background: #eef0f1;
    min-width: 50px;
    text-align: center;
    padding: 3px 10px 2px 10px;
    border-radius: 5px;
  }
}
.selected-product {
  background: #fdf0e8;
}
.product-description {
  height: calc(100vh - 70px);
  background: white;
  width: 100vw;
}
.close-description {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  background: white;
  border-radius: 40px;
  z-index: 3;
}
.close-description {
  .close-rel-mask-desk-menu {
    position: relative;
    width: 100%;
    height: 100%;
    .btn-section-tap-mask {
      width: 60px;
      height: 60px;
      position: absolute;
      top: -15px;
      left: -15px;
      // background-color: rgba(0,0,0,0.2);
      z-index: 5;
    }
    .x-btn {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }
}

.x-btn {
  padding: 6px 8px 9px 8px;
}
.product-description-img {
  width: 100vw;
  height: 250px;
  overflow: hidden;
}
.product-description-img-abs {
  width: 100vw;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.image-product {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

[venue="87"] .image-product {
  object-fit: contain;
}

.fade {
  width: 154%;
  height: 100%;
  position: relative;
  top: 1%;
  left: -5%;
  z-index: 2;
  -webkit-transform: scaleX(-1);
  transform: scaleY(-1);

  // box-shadow: -52px 87px 68px #de1dde;
  -webkit-box-shadow: -50px 102px 119px white;
  -moz-box-shadow: -50px 102px 119px white;
  box-shadow: -50px 102px 119px white;
}
.product-description-width {
  width: 84%;
  margin: 0 auto;
  padding-top: 10px;
  box-shadow: 0px 20px 30px -20px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 2;
}
.product-description-title {
  font-size: 18px;
  font-weight: 600;
}
.product-description-price {
  font-size: 18px;
  font-weight: 600;
  color: #ff7f00;
}
.product-description-text {
  font-size: 12px;
  font-weight: 600;
  color: #6b6b6b;
  margin-top: 18px;
}
.product-description-btn {
  font-size: 18px;
  color: white;
  background-color: #ff7f00;
  font-weight: 600;
  padding: 20px 0 19px 0;
  text-align: center;
  border-radius: 15px;
  width: 84%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.product-desc-menu-contianer-btn {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 110px;
  padding-top: 10px;
  align-items: center;
  z-index: 10;
  background-color: white;
  // box-shadow: 0px 20px 30px -20px rgba(255, 255, 255, 0.1);
}
.scroll_container-desc- {
  width: 100%;
  height: 100%;
  overflow: auto;
  // position: absolute;
  // z-index: 9;
  // background-color: white;
}
.product-description-img-abs {
  z-index: 12;
}
.close-description {
  z-index: 14;
}

.cart-products {
  background: #ff7f00;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  text-align: center;
  color: white;
  box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 15;
  bottom: 30px;
  float: right;
  margin-right: -15px;
  position: fixed;
  right: 50px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.cart-products-nr {
  font-size: 30px;
  font-weight: 600;
  padding-top: 3px;
}
.cart-products-text {
  font-size: 10px;
  font-weight: 600;
  margin-top: -8px;
}
/* #Products */
/* Order Selection */
.my-order-inner {
  width: 84%;
  margin: 0 auto;
  padding-top: 20px;
}
.x-btn-selection {
  position: absolute;
  right: 0;
  top: 0;
  width: 50;
  display: flex;
  width: 63px;
  height: 63px;
  align-items: center;
  justify-content: center;
}
.my-order-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
}
.my-order-product {
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-style: solid;
  border-top-color: #f1f3f5;
  border-bottom-color: #f1f3f5;
  border-width: 1px;
  border-left-color: transparent;
  border-right-color: transparent;
}
.inc-dec-btn-rel-cont-menu {
  position: relative;
  .btn-section-tap-mask {
    width: 37px;
    height: 35px;
    position: absolute;
    top: -6px;
    left: -12px;
    //background-color: rgba(0,0,0,0.2);
  }
}
.final-price {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 48px;
}
.final-price.with-order-step {
  padding: 20px 0 20px;
}
.my-order-product-name,
.total-text-price,
.actual-qty,
.total-num-price,
.my-order-product-price {
  font-size: 14px;
  font-weight: 600;
}
.my-order-product-price {
  width: 60px;
  text-align: right;
}
.my-order-product-name {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  & > :first-child {
    width: 100%;
  }
  .assoc-product-menu {
    font-size: 10px;
    font-weight: 600;
    color: #989ea3;
    text-align: left;
  }
}

.cart-rigth-meniu-part-item {
  display: flex;
  justify-content: flex-end;
}

.qty-inner {
  min-width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 7px;
}
/* #Order Selection */
/* Intro */
.content-intro {
  height: 100vh;
  max-width: 100vw;
  background-position: center;
  background-size: cover;
}
.intro-top-section {
  //background: url(./../../../assets/img/white_fade_inverted.png);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 65%;
}
.intro-bottom-section {
  // background: url(./../../../assets/img/white_fade.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  height: 35%;
}
.intro-title {
  padding: 50px 0 20px 0;
  font-size: 22px;
  font-weight: 600;
}

.intro-btn {
  background: #ff7f00;
  display: flex;
  justify-content: space-between;
  width: 75%;
  padding: 15px 20px;
  border-radius: 15px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.intro-btn-text,
.intro-btn-arrow {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.arrow-intro {
  padding-top: 4px;
}
/* #Intro */

// } end media

//Gabi
.content-container-menu {
  position: absolute;
  width: 100% !important;
  opacity: 1;
  transition: opacity 0.1s;
}
.content-container-menu.content-section {
  width: 84% !important;
  left: 7%;
}
.intro-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 65px;
  .intro-btn {
    width: 86%;
    padding: 15px 20px;
    border-radius: 15px;
  }
  position: fixed;
  bottom: 0;
}
.cut-first-el-padding {
  position: relative;
  top: -22px;
  z-index: 7;
}
.cut-first-el-padding-products {
  position: relative;
  top: -13px;
  z-index: 7;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
.category-image {
  background-size: cover;
  background-position: center;
}
.search-form {
  position: relative;
  height: 47px;
  width: 100%;
  background-color: white;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  z-index: 8;
}
.left-category {
  padding-top: 22px;
  display: flex;
  flex-direction: column;
  width: 145px;
}
.remove_margin_top {
  margin-bottom: -22px;
}
.arrow-back-container-top {
  width: 80px;
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  top: 8px;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
}
.page-title.jost {
  width: 100%;
  text-align: center;
}
.form {
  button {
    outline: none;
    &:active {
      -webkit-tap-highlight-color: transparent;
      border-color: transparent;
    }
  }
}

.no-result-message {
  background-color: transparent;
  padding: 0 10px;

  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

//modals
.menu-modal-generic-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 11;
  & > div:first-child {
    max-height: calc(100vh - 70px);
    width: 100vw;
    overflow: auto;
    background-color: white;
  }
  .product-description {
    height: calc(100vh - 70px);
    position: relative;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    background-position: top;
    background-size: 100% auto;
  }
}

$modal_background_active: rgba(222, 197, 182, 0.7);

.modal-closing-animation-class {
  background-color: transparent;
  animation-name: modal_closing_animation;
}
.modal-open-animation-class {
  background-color: $modal_background_active;
  animation-name: modal_open_animation;
}
@keyframes modal_closing_animation {
  0% {
    background: $modal_background_active;
  }
  100% {
    background: transparent;
  }
}
@keyframes modal_open_animation {
  0% {
    background: transparent;
  }
  100% {
    background: $modal_background_active;
  }
}

.modal-closing-animation-class-fr-chid {
  transform: translate(0px, calc(100vh - 70px));
  animation-name: modal_closing_animation_fr_chid;
}
.modal-open-animation-class-fr-chid {
  transform: translate(0px, 0px);
  animation-name: modal_open_animation_fr_chid;
}
@keyframes modal_closing_animation_fr_chid {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, calc(100vh - 70px));
  }
}
@keyframes modal_open_animation_fr_chid {
  0% {
    transform: translate(0px, calc(100vh - 70px));
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #ff7f01 radial-gradient(circle, transparent 1%, #ff7f01 1%) center/15000%;
}
.ripple:active {
  background-color: #ff8a01;
  background-size: 100%;
  transition: background 0s;
}

//page navigation animation
.menu-page-fade-in-anim-class {
  opacity: 1;
  animation-name: menu_page_fade_in_anim;
}
.menu-page-fade-out-anim-class {
  opacity: 0;
  animation-name: menu_page_fade_out_anim;
}
@keyframes menu_page_fade_in_anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu_page_fade_out_anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-order {
  background: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: auto;
  max-height: calc(100vh - 70px);
  overflow: auto;
}
.menu-product-checked {
  background: #faede5;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  .product-add-btn {
    .plus-btn {
      display: none !important;
    }
    .check-btn {
      display: inline-block !important;
    }
  }
  .product-weight {
    > div {
      background-color: #f3e5dc;
    }
  }
}
.modal-product-assoc-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & > div {
    margin-bottom: 20px;
  }
}
.menu-btn-container-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ff7f00;
  padding: 15px 20px;
  border-radius: 15px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}
.select-btn-container-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  padding: 15px 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-color: #f1f3f5;
  border-width: 1px;
  .select-label-container,
  option {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
  option {
    color: #6b6b6b;
  }
  .arrow-intro {
    color: black;
  }
  .intro-btn-text {
    color: black;
  }
  .select-arrow-down {
    font-size: 16px;
    font-weight: 600;
    color: black;
    .arrow-select {
      color: "black";
      // width: 16px;
      // height: 8px;
    }
  }
  select {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  option: {
    color: black;
  }
}
.select-btn-container-item.error_select {
  .select-label-container {
    color: red !important;
  }
  .arrow-select {
    path {
      fill: red;
    }
  }
}
.error_text {
  color: red;
}
.product-hands-on-description {
  width: 100%;
  margin-top: 9px;
  font-size: 14px;
  p {
    margin: 0;
  }
}
@media screen and (max-width: 545px) {
  .categories {
    justify-content: space-between;
  }
}
@media screen and (min-width: 546px) {
  .categories {
    justify-content: center;
  }
  .left-category {
    margin-right: 10px;
  }
}
@media screen and (max-width: 355px) {
  .categories {
    justify-content: center;
    align-items: center;
    .left-category {
      width: 100%;
      .category-image {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

@media screen and (min-width: 768px) {
}
.background-menu-class {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  background: #fdf0e8;
}
